<template>
  <div id="app">
    <loading :overlay="overlay"></loading>
    <v-app id="inspire" :class="{ 'cursor-wait': isLoading }">
      <v-navigation-drawer permanent expand-on-hover v-bind:width="400" :clipped="true" fixed app v-if="drawer">
        <v-list nav dense>


          <v-list-item link>
            <v-list-item-icon>
              <v-icon color="primary">mdi-alert</v-icon>
            </v-list-item-icon>
            <v-list-item-title>

              <v-list-group sub-group no-action>


                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Riesgo Fiscal</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item :disabled="!isInvoice || (!allowIssued && !allowReceived)" to="/riesgo">
                  <v-list-item-content>
                    <v-list-item-title>Análisis de los Riesgos Fiscales</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

              </v-list-group>



            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon color="primary">mdi-bookshelf</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <v-list-group sub-group no-action>


                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Miscelánea</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item :disabled="!isInvoice || (!allowIssued && !allowReceived)" to="/conceptos/general">
                  <v-list-item-content>
                    <v-list-item-title>Reporte General de Conceptos</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isInvoice || (!allowIssued && !allowReceived)" to="/conceptos/prodserv">
                  <v-list-item-content>
                    <v-list-item-title>Reporte de Precios de Productos<br />y
                      Servicios</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isInvoice" to="/Miscelanea/Clientes">
                  <v-list-item-content>
                    <v-list-item-title>Reporte de Clientes</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isInvoice" to="/Miscelanea/Proveedores">
                  <v-list-item-content>
                    <v-list-item-title>Reporte de Proveedores</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isInvoice" v-if="conFiel" to="/Miscelanea/Cancelados">
                  <v-list-item-content>
                    <v-list-item-title>Sincronización SAT</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item to="/Financiero/FlujoEfectivo" :disabled="permisoFull">
                  <v-list-item-content>
                    <v-list-item-title>Flujo de efectivo</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>

            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon color="primary">mdi-compare-horizontal</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <v-list-group sub-group no-action>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Reportes conciliatorios</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item :disabled="!isInvoice || (!allowIssued && !allowReceived)" to="/conciliatorio/resumen">
                  <v-list-item-content>
                    <v-list-item-title>Resumen de la cuenta</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

               

                <v-list-item link>
                  <v-list-item-title class="move-left">
                    <v-list-group sub-group no-action prepend-icon="mdi-dot"  @click="toggleDuplicity" >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>
                           <v-icon>{{ isDuplicityOpen ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                            Posible duplicidad</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <v-list-item :disabled="!isInvoice || !allowIssued" to="/conciliatorio/ResumenDuplicados" class="move-right">
                        <v-list-item-content>
                          <v-list-item-title>Duplicidad por Datos Generales</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item :disabled="!isInvoice || !allowIssued"
                        to="/conciliatorio/ResumenDuplicadosCadenaOriginal" class="move-right">
                        <v-list-item-content>
                          <v-list-item-title>Duplicidad por Todo el CFDI</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list-item-title>
                </v-list-item>



                <v-list-item :disabled="!isInvoice || !allowIssued" to="/conciliatorio/emitidos/todos">
                  <v-list-item-content>
                    <v-list-item-title>Emitidos No Cancelados</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isInvoice || !allowReceived" to="/conciliatorio/recibidos/todos">
                  <v-list-item-content>
                    <v-list-item-title>Recibidos No Cancelados</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isInvoice || !allowIssued" to="/conciliatorio/autofacturas">
                  <v-list-item-content>
                    <v-list-item-title>Reporte de Autofacturas</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                

                <v-list-item :disabled="!isInvoice || !allowReceived" to="/conciliatorio/desglose">
                  <v-list-item-content>
                    <v-list-item-title>Conciliatorio No Cancelados <br /> con Desglose de Impuestos</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isInvoice || (!allowIssued && !allowReceived)" to="/conciliatorio/cancelados">
                  <v-list-item-content>
                    <v-list-item-title>Cancelados</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isInvoice || (!allowIssued && !allowReceived)"
                  to="/conciliatorio/fechacancelacion">
                  <v-list-item-content>
                    <v-list-item-title>Documentos por fecha de <br />cancelación</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item :disabled="!isInvoice || (!allowIssued && !allowReceived)" to="/AgeAnalysis">
                  <v-list-item-content>
                    <v-list-item-title>Antigüedad de saldos</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item :disabled="!isInvoice || (!allowIssued && !allowReceived)" to="/ConciliatorioIvaSat">
                  <v-list-item-content>
                    <v-list-item-title>Integración IVA-SAT</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>


                <v-list-item :disabled="!isInvoice || !allowIssued" to="/retenciones/emitidas/todas">
                  <v-list-item-content>
                    <v-list-item-title>Retenciones emitidas</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isInvoice || !allowReceived" to="/retenciones/recibidas/todas">
                  <v-list-item-content>
                    <v-list-item-title>Retenciones recibidas</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isInvoice || (!allowIssued && !allowReceived)"
                  to="/retenciones/fechacancelacion">
                  <v-list-item-content>
                    <v-list-item-title>Retenciones por fecha de
                      <br />cancelación
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isInvoice || (!allowIssued && !allowReceived)" to="/Miscelanea/Deuda">
                  <v-list-item-content>
                    <v-list-item-title>Listado Deudores/Acreedores</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isInvoice" to="/Miscelanea/Comparador">
                  <v-list-item-content>
                    <v-list-item-title>Conciliación ERP</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon color="primary">mdi-human-handsup</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <v-list-group sub-group no-action>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Reportes de Nómina</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item :disabled="!isPayroll || !allowIssued" to="/Nomina/ReporteNomina">
                  <v-list-item-content>
                    <v-list-item-title>Reporte CFDIs</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/Nomina/DetalleNomina" :disabled="!isPayroll || !allowIssued">
                  <v-list-item-content>
                    <v-list-item-title>Reporte de complemento</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isPayroll || !allowIssued" to="/Nomina/DetalleNominaDesglose">
                  <v-list-item-content>
                    <v-list-item-title>Reporte de nómina con desglose</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isPayroll || !allowIssued" to="/Nomina/DetalleNominaDesglose2">
                  <v-list-item-content>
                    <v-list-item-title>Reporte de nómina <br />con desglose versión 2</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isPayroll || !allowIssued" to="/Nomina/ReporteNomina/Cancelados">
                  <v-list-item-content>
                    <v-list-item-title>Reporte CFDI Cancelados</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isPayroll || !allowIssued" to="/Nomina/ReporteNominaFechaCancelacion">
                  <v-list-item-content>
                    <v-list-item-title>Reporte CFDI por fecha <br />de
                      cancelación</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isPayroll || !allowIssued" to="/Nomina/ControlNomina">
                  <v-list-item-content>
                    <v-list-item-title>Control de CFDI de nómina</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

              </v-list-group>
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon color="primary">mdi-chart-line</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <v-list-group sub-group no-action>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Análisis de Impuestos</v-list-item-title>
                  </v-list-item-content>
                </template>
                
                <v-list-item :disabled="!isInvoice || (!allowIssued && !allowReceived)" to="/conciliatorio/impuestosLocales">
                  <v-list-item-content>
                    <v-list-item-title>Impuestos Locales No Cancelados</v-list-item-title>
                  </v-list-item-content>
                </v-list-item> 
                
                <v-list-item :disabled="!isInvoice || (!allowIssued && !allowReceived)"
                  to="/AnalisisImpuestos/MontoImpuestos">
                  <v-list-item-content>
                    <v-list-item-title>Montos por Impuestos<br />
                      Facturados</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="!isInvoice" to="/AnalisisImpuestos/ImpuestosPagadosCobrados">
                  <v-list-item-content>
                    <v-list-item-title>Impuestos Efectivamente<br />
                      Cobrados/Pagados</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item
                  to="/AnalisisImpuestos/ImpuestosPagadosCobradosREP"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >Impuestos efectivamente<br />
                      Cobrados/Pagados<br />
                      por Fecha de REP</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item> -->
                <v-list-item :disabled="!isPayroll || !allowIssued" to="/AnalisisImpuestos/Nomina">
                  <v-list-item-content>
                    <v-list-item-title>Análisis de Impuestos<br />
                      de Nómina</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon color="primary">mdi-text-box-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <v-list-group sub-group no-action>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Relaciones entre documentos</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item to="/Relacionados/Todos" :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>Análisis de CFDI vs <br />CFDI's
                      Relacionados</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/Income/Todos" :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>Análisis de CFDI vs <br />Comprobantes de
                      Pago</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                
                


                <v-list-item link>
                  <v-list-item-title class="move-left">
                    <v-list-group sub-group no-action prepend-icon="mdi-dot"  @click="toggleRel" >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>
                           <v-icon>{{ isRelacionadosOpen ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                           Análisis de Comprobantes de <br /> <span style="margin-left: 4ch;">Pago vs CFDI</span></v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <v-list-item :disabled="!isInvoice || !allowIssued" to="/Inconsistencias/PaymentVsCfdi" class="move-right">
                        <v-list-item-content>
                          <v-list-item-title>Por Fecha de Emisión</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item :disabled="!isInvoice || !allowIssued"
                        to="/Inconsistencias/PaymentVsCfdiByDate" class="move-right">
                        <v-list-item-content>
                          <v-list-item-title>Por Fecha de Pago</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list-item-title>
                </v-list-item>
                
              
                <v-list-item to="/Relacionados/CFDIS" :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>CFDI's y sus relaciones </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>



              </v-list-group>
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon color="primary">mdi-vector-difference</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <v-list-group sub-group no-action>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Inconsistencias Fiscales</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item to="/Pagos/NoEncontrados" :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>CFDIs de Pago <br />con documento relacionado inexistente
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/Pagos/NoPermitidos" :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>Relaciones no permitidas<br /> en CFDIs de Pago</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/Pagos/RelCancelados" :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>Facturas canceladas que están<br />en Comprobantes de Pago
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/Pagos/Extemporaneidad" :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>CFDIs de Pago extemporáneos</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/Inconsistencias/MetodoPago" :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>CFDIs con Inconsistencias entre<br /> Método y Forma de Pago</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/Auditoria/RegimenFiscal" :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>Regímenes Fiscal</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/Auditoria/LugarExpedicion" :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>Lugar Expedición</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item to="/Inconsistencias/SustituidosNoCancelados"
                  :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>CFDIs Relacionados Sustituidos<br /> que no han Sido Cancelados</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item to="/Inconsistencias/NoSustituidosCancelados"
                  :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>CFDIs Relacionados<br /> (NO Sustituidos) Cancelados</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item to="/Inconsistencias/RelacionadosInexistentes"
                  :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>CFDIs Relacionados que<br /> NO existen en XDATA</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

              </v-list-group>
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon color="primary">mdi-checkbox-multiple-blank</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <v-list-group sub-group no-action>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Inconsistencias Comerciales</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item to="/Auditoria/FormaPago" :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>Forma de Pago</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/Auditoria/UsoCFDI" :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>Usos CFDIs</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/Auditoria/Moneda" :disabled="permisoFull || (!allowIssued && !allowReceived)">
                  <v-list-item-content>
                    <v-list-item-title>Moneda</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon color="primary">mdi-view-list</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <v-list-group sub-group no-action>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Coincidencias en listas negras</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item to="/ListasNegras/ListaNegra" :disabled="permisoFull">
                  <v-list-item-content>
                    <v-list-item-title>Alertas Lista Negra 69</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/ListasNegras/ListaNegraB" :disabled="permisoFull">
                  <v-list-item-content>
                    <v-list-item-title>Alertas Lista Negra 69B</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item to="/ListasNegras/ListaNegraResumen" :disabled="permisoFull">
                  <v-list-item-content>
                    <v-list-item-title>Coincidencias Lista Negra 69B</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app color="white" dark elevation="5" fixed clipped-left>

        <div class="tooltip"> <v-avatar tile width="100%">
            <img v-on:click="goHome" src="./assets/logo.png" alt="Portal" />
          </v-avatar>
          <span class="tooltiptext">Home</span>
        </div>
         <!--<v-btn icon v-show="$store.state.token.length" to="/test">
          <div class="tooltip"><v-icon color="primary">mdi-test-tube</v-icon>
            <span class="tooltiptext">TEST</span>
          </div>
        </v-btn>-->
        <!-- <v-toolbar-title style="width: 400px" class="ml-0 pl-4 d-none d-sm-flex">MY$uite X-Data</v-toolbar-title>-->
        <v-spacer></v-spacer>
        <span class="mr-5 d-none d-sm-flex" style="color:#1185ab;">{{ getUser }}</span>

        <v-btn icon v-show="$store.state.mostrarHeader" @click="ConsultarNoticias">
          <div class="tooltip"><v-icon color="primary">mdi-newspaper-variant-outline</v-icon>
            <span class="tooltiptext2">¿Qué hay de nuevo?</span>
          </div>
        </v-btn>


        <v-btn :disabled="!allowIssued && !allowReceived" icon v-show="$store.state.mostrarHeader"
          @click="showDialogSearchGuid = true">
          <div class="tooltip"><v-icon color="primary">mdi-magnify</v-icon>
            <span class="tooltiptext2">Bucar por GUID</span>
          </div>
        </v-btn>

        <v-btn :disabled="!allowIssued && !allowReceived" icon v-show="$store.state.token.length" to="/metricas/metricas">
          <div class="tooltip"><v-icon color="primary">mdi-bell-ring-outline</v-icon>
            <span class="tooltiptext">Cambios</span>
          </div>
        </v-btn>

        <v-btn icon v-show="$store.state.token.length" to="/perfil">
          <div class="tooltip"><v-icon color="primary">mdi-account</v-icon>
            <span class="tooltiptext">Mi Perfil</span>
          </div>
        </v-btn>


        <v-menu bottom left v-if="drawer">
          <template v-slot:activator="{ on, attrs }">

            <v-btn icon v-bind="attrs" v-on="on">
              <div class="tooltip"><v-icon color="primary">mdi-cog</v-icon>
                <span class="tooltiptext">Ajustes</span>
              </div>
            </v-btn>


          </template>

          <v-list>
            <v-list-item v-show="esAdmin" to="/usuarios">
              <v-list-item-content>
                <v-list-item-title>Administración de Usuarios</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/Miscelanea/Validaciones">
              <v-list-item-content>
                <v-list-item-title>Parámetros de Auditoría</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/ContratoServicios">
              <v-list-item-content>
                <v-list-item-title>Contrato de licencia de uso</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/DesacargaAutomatica/GuardarFIEL">
              <v-list-item-content>
                <v-list-item-title>Actualizar FIEL</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="manageTags">
              <v-list-item-content>
                <v-list-item-title>Administrador de etiquetas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn icon v-show="$store.state.token.length" to="/" @click="CerrarApp">
          <div class="tooltip"><v-icon color="primary">mdi-exit-to-app</v-icon>
            <span class="tooltiptext3">Salir</span>
          </div>
        </v-btn>
      </v-app-bar>



      <v-dialog v-model="showDialogStatusMain" persistent max-width="700px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="showDialogStatusMain = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Detalle documento : {{ itemSelectedMain.serie }} -
              {{ itemSelectedMain.folio }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-simple-table class="ma-3">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Estado</th>
                    <th>Cancelable</th>
                    <th>Estado Cancelación</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="altura">
                    <td>{{ selectedStatusMain.estado }}</td>
                    <td>{{ selectedStatusMain.cancelable }}</td>
                    <td>{{ selectedStatusMain.estado_cancelacion }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showAdminTags" persistent min-width="800px" max-width="800px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="showAdminTags = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Administrador de Etiquetas</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>

            <v-alert :value="alert_errorTag" type="error" transition="scale-transition">{{ errorMessageTag }}
            </v-alert>
            <v-alert :value="alert_okTag" type="success" transition="scale-transition">{{ okMessageTag }}</v-alert>

            <v-simple-table class="ma-3">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in itemsTags" :key="item.id" class="altura">
                    <td>
                      <v-icon large :color="item.Color">
                        mdi-checkbox-blank-circle
                      </v-icon>
                    </td>
                    <td>
                      <v-text-field label="Descripción" type="text" v-model="item.Description" maxlength="140">
                      </v-text-field>
                    </td>
                    <td class="pa-0 md-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="ActualizarTag(item.Color, item.Description)" icon color="#4aa832" v-on="on">
                            <v-icon>mdi-content-save</v-icon>
                          </v-btn>
                        </template>
                        <span>Actualizar Etiqueta</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

        </v-card>
      </v-dialog>

      <!--<v-dialog v-model="showDialogGuid" persistent max-width="1000px"></v-dialog>-->



      <dialogo ref="dialog" :itemFind="itemFind"></dialogo>


      <v-dialog v-model="showDialogSearchGuid" persistent max-width="800px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="showDialogSearchGuid = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Buscar documentos por GUID</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-col cols="12">
            <v-alert :value="alert_errorR" type="error" transition="scale-transition">{{ errorMessageR }}</v-alert>
          </v-col>
          <v-card class="mx-auto pa-2 ma-2" min-width="600" max-width="600" elevation="0">
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field v-model="guidSearch" label="Buscar por GUID" hide-details v-on:input="putLower"
                  prepend-icon="mdi-magnify" maxlength="36" class="mx-3"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn color="primary" white v-on:click="buscarPorGuid" :disabled="!showSearchButtonGUID">Buscar</v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showDialogSearchGuid = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog v-model="showDialogNews" max-width="800" persistent>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="showDialogNews = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>¿Qué hay de nuevo?</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <div class="pa-2 ma-2" pa-2 v-html="html_content"> </div>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showDialogNews = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showDialogRetencionGuid" persistent max-width="1000px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="showDialogRetencionGuid = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Detalle documento : {{ itemFind.id }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-simple-table class="ma-3">
              <template v-slot:default>
                <tr>
                  <td>
                    <v-card class="mx-auto pa-2 ma-2" max-width="344" min-width="420" min-height="190">
                      <p class="display-1 text--primary">Emisor</p>
                      <div class="body-1">
                        Razon Social: {{ itemFind.emisor.nombre }}
                      </div>
                      <div class="body-1">RFC: {{ itemFind.emisor.rfc }}</div>
                    </v-card>
                  </td>
                  <td>
                    <v-card class="mx-auto pa-2 ma-2" max-width="344" min-width="420" min-height="190">
                      <p class="display-1 text--primary">Receptor</p>
                      <div class="body-1">
                        Nacionalidad: {{ itemFind.receptor.nacionalidad }}
                      </div>
                      <div class="body-1" v-if="itemFind.receptor.extranjero == null">
                        Razon Social: {{ itemFind.receptor.nacional.nombre }}
                      </div>
                      <div class="body-1" v-else>
                        Razon Social: {{ itemFind.receptor.extranjero.nombre }}
                      </div>
                      <div class="body-1" v-if="itemFind.receptor.extranjero == null">
                        RFC: {{ itemFind.receptor.nacional.rfc }}
                      </div>
                      <div class="body-1" v-else>
                        RFC: {{ itemFind.receptor.extranjero.rfc }}
                      </div>
                    </v-card>
                  </td>
                </tr>
              </template>
            </v-simple-table>
            <br />
            <v-label>Folio Fiscal: {{ itemFind.id }}</v-label>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="retrieveXML(itemFind)" icon color="blue" v-on="on">
                  <v-icon>mdi-xml</v-icon>
                </v-btn>
              </template>
              <span>Descargar XML</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="retrievePDFRetencion(itemFind)" icon color="red" v-on="on">
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>
              </template>
              <span>Descargar PDF</span>
            </v-tooltip>
            <br />

            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Clave Retencion</th>
                    <th>Folio Interno</th>
                    <th>Tipo Comprobante</th>
                    <th>Fecha Emisión</th>
                    <th>Fecha Timbre</th>
                    <th>Fecha Cancelación</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="altura">
                    <td>{{ itemFind.cve_retenc }}</td>
                    <td>{{ itemFind.folio_int }}</td>
                    <td>{{ tipoComp(itemFind.tipo_comprobante) }}</td>
                    <td>{{ formatFecha(itemFind.fecha_emision) }}</td>
                    <td>{{ formatFecha(itemFind.fecha_timbre) }}</td>
                    <td>{{ formatFecha(itemFind.fecha_cancelacion) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <br />
            <p class="font-weight-black">Totales:</p>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-right">Gravado</th>
                    <th class="text-left">Exento</th>
                    <th class="text-right">Retenido</th>
                    <th class="text-right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="altura">
                    <td align="right">
                      {{ currencyFormat(itemFind.totales.gravado) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(itemFind.totales.exento) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(itemFind.totales.retenido) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(itemFind.totales.total) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showDialogRetencionGuid = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <router-view class="ml-14 pt-13"> </router-view>

      <v-col cols="12" v-if="alert_error || alert_ok">
        <v-alert class="fixed-alert" border="bottom" :value="alert_error" type="error" transition="scale-transition">
          <span>{{ alertMessage }} </span>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon color="white" class="close-btn" @click="closeError()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-alert>
        <v-alert class="fixed-alert" border="bottom" :value="alert_ok" type="success"
          transition="scale-transition"><span>{{ alertMessage }}</span>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon color="white" class="close-btn" @click="closeOk()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-alert>
      </v-col>


      <v-footer fill-width color="primary" app>
        <v-card flat tile width="100%" color="primary" class="text-center">
          <v-card-text class="white--text">© MYSUITE SERVICES SA DE CV. Todos los derechos
            reservados.</v-card-text>
        </v-card>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import Loading from "./components/Loading";
import service from "./scripts/services.js";
import helpers from "./scripts/helpers.js";
import Dialogo from './components/Componentes/DialogoBuscar.vue';
export default {
  name: "App",

  components: {
    Loading,
    Dialogo
  },

  data: () => ({}),
  props: {
    titulo: String,
  },
  data() {
    return {
      mini: true,
      drawerIn: false,
      guidSearch: "",
      alert_errorR: false,
      errorMessageR: "",
      itemFind: {
        id: "",
        emisor_rfc: "",
        emisor_nombre: "",
        regimen: "",
        receptor_nombre: "",
        residencia: "",
        receptor_rfc: "",
        usocfdi: "",
        fecha_cancelacion: "",
        fecha_carga: "",
        fecha_emision: "",
        fecha_timbre: "",
        folio: "",
        forma_pago: "",
        descuento: 0,
        lugar_expedicion: "",
        metodo_pago: "",
        moneda: "",
        serie: "",
        subtotal: 0,
        tipo: "",
        tipo_cambio: 1,
        tipo_comprobante: "",
        total: 0,
        total_retenciones: 0,
        total_traslados: 0,
        retencion_isr: 0,
        retencion_iva: 0,
        retencion_ieps: 0,
        traslado_iva: 0,
        traslado_ieps: 0,
      },
      showDialogGuid: false,
      showDialogRetencionGuid: false,
      showDialogSearchGuid: false,
      showDialogStatusMain: false,
      showDialogNews: false,
      itemSelectedMain: {
        id: "",
        emisor_rfc: "",
        emisor_nombre: "",
        regimen: "",
        receptor_nombre: "",
        residencia: "",
        receptor_rfc: "",
        usocfdi: "",
        fecha_cancelacion: "",
        fecha_carga: "",
        fecha_emision: "",
        fecha_timbre: "",
        folio: "",
        forma_pago: "",
        descuento: 0,
        lugar_expedicion: "",
        metodo_pago: "",
        moneda: "",
        serie: "",
        subtotal: 0,
        tipo: "",
        tipo_cambio: 1,
        tipo_comprobante: "",
        total: 0,
        total_retenciones: 0,
        total_traslados: 0,
        retencion_isr: 0,
        retencion_iva: 0,
        retencion_ieps: 0,
        traslado_iva: 0,
        traslado_ieps: 0,
      },
      selectedStatusMain: null,
      html_content: "",
      showAdminTags: false,
      itemsTags: [],
      errorMessageTag: "",
      okMessageTag: "",
      alert_errorTag: false,
      alert_okTag: false,
      showExpediente: false,
      isLoading: false,
      isDuplicityOpen: false,
      isRelacionadosOpen: false,

    };
  },
  methods: {
    toggleDuplicity() {
      this.isDuplicityOpen = !this.isDuplicityOpen;
    },
    toggleRel() {
      this.isRelacionadosOpen = !this.isRelacionadosOpen;
    },

    CerrarApp(){
      this.$store.commit("setMostrarHeader", false);
    },
    openDialog() {
      this.$refs.dialog.showDialogGuid = true;
    },
    goHome() {
      this.$router.push({
        path: "/Miscelanea/Cancelados",
      });
    },

    formatFecha(fecha) {

      return helpers.formatFecha(fecha);
    },

    ActualizarTag(col, desc) {
      var jsonData = {
        token: this.$store.state.token,
        color: col,
        description: desc
      };

      service
        .api()
        .TagUpsertCatalog(jsonData)
        .then((response) => {
          if (response.result) {

            this.alert_okTag = true;
            this.okMessageTag = "Etiqueta Actualizada";
            setTimeout(() => {
              this.alert_okTag = false;
              this.okMessageTag = "";
            }, 5000);

          } else {
            this.alert_errorTag = true;
            this.errorMessageTag = response.errorMessage;
            setTimeout(() => {
              this.alert_errorTag = false;
              this.errorMessageTag = "";
            }, 10000);
          }
        });

    },
    manageTags() {

      var jsonData = {
        token: this.$store.state.token,
      };

      service
        .api()
        .TagCatalog(jsonData)
        .then((response) => {
          if (response.result) {

            let payload = JSON.parse(response.payload);
            this.itemsTags = payload;
            this.$store.commit("setOverlay", false);
            this.showAdminTags = true;

          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);

          }
        });
    },
    ConsultarNoticias() {
      var jsonData = {
        token: this.$store.state.token,
      };

      service
        .api()
        .News(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.html_content = helpers.Base64Decode(response.payload);
            this.showDialogNews = true;
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);

          }
        });
    },
    putLower() {
      this.guidSearch = this.guidSearch.toLowerCase();
    },



    ConsultarEstadoDoc(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: item.tipo,
        uuid: item.id,
        issuedDate: item.fecha_emision,
        issuer: item.emisor.rfc,
        receiver: item.receptor.rfc,
        total: item.total,
      };

      service
        .api()
        .Consult(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.selectedStatusMain = JSON.parse(response.payload);
            this.itemSelectedMain = item;
            this.showDialogStatusMain = true;
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);

          }
        });
    },
    retrieveXML(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: item.tipo,
        uuid: item.id,
        issuedDate: item.fecha_emision,
      };
      service
        .api()
        .RetrieveXML(jsonData)
        .then((response) => {
          if (response.result) {
            let urlXML = response.payload;
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              window.open(urlXML, "_blank");
            } else {
              helpers.openNewBackgroundTab(urlXML);
            }
            setTimeout(() => {
              this.$store.commit("setOverlay", false);
            }, 1000);
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrievePDF(item) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (item.tipo_comprobante === "R")
        par = item.tipo + "|" + item.pac + "|" + item.emisor_rfc;
      else par = item.tipo + "|" + item.pac + "|";
      let parameters = par + "|" + item.id + "|" + item.fecha_emision;
      let urlPDF = "";

      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");
      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);
        this.$store.commit("setOverlay", false);
      }, 3000);
    },
    retrievePDFRetencion(item) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (item.tipo === "R") par = item.tipo + "||" + item.emisor.rfc;
      else par = item.tipo + "||";
      let parameters = par + "|" + item.id + "|" + item.fecha_emision + "|RET";
      let urlPDF = "";

      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);
        this.$store.commit("setOverlay", false);
      }, 3000);
    },

    buscarPorGuid() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        uuid: this.guidSearch,
      };
      if (this.$store.state.isRetencion) {
        service
          .api()
          .SearchRetencionesByGUID(jsonData)
          .then((response) => {
            if (response.result) {
              let payload = JSON.parse(response.payload);
              this.itemFind = payload;

              if (this.itemFind.tipo === 'E') {
                if (!this.allowIssued) {

                  this.alert_errorR = true;
                  this.errorMessageR = "Documento no encontrado, no cuenta con permiso para ver documentos Emitidos";
                  setTimeout(() => {
                    this.alert_errorR = false;
                    this.errorMessageR = "";
                  }, 10000);
                  return;
                }
              }
              if (this.itemFind.tipo === 'R') {
                if (!this.allowReceived) {

                  this.alert_errorR = true;
                  this.errorMessageR = "Documento no encontrado, no cuenta con permiso para ver documentos Recibidos";
                  setTimeout(() => {
                    this.alert_errorR = false;
                    this.errorMessageR = "";
                  }, 10000);
                  return;
                }
              }
              //this.showDialogSearchGuid = false;
              this.showDialogRetencionGuid = true;
              this.guidSearch = "";
            } else {
              this.guidSearch = "";
              this.alert_errorR = true;
              this.errorMessageR = response.errorMessage;

              setTimeout(() => {
                this.alert_errorR = false;
                this.errorMessageR = "";
              }, 10000);
            }
          });
      } else {
        service
          .api()
          .SearchByGUID(jsonData)
          .then((response) => {
            if (response.result) {
              let payload = JSON.parse(response.payload);
              this.itemFind = payload;


              if (this.itemFind.tipo === 'E') {
                if (!this.allowIssued) {

                  this.alert_errorR = true;
                  this.errorMessageR = "Documento no encontrado, no cuenta con permiso para ver documentos Emitidos";
                  setTimeout(() => {
                    this.alert_errorR = false;
                    this.errorMessageR = "";
                  }, 10000);
                  return;
                }
              }
              if (this.itemFind.tipo === 'R') {
                if (!this.allowReceived) {

                  this.alert_errorR = true;
                  this.errorMessageR = "Documento no encontrado, no cuenta con permiso para ver documentos Recibidos";
                  setTimeout(() => {
                    this.alert_errorR = false;
                    this.errorMessageR = "";
                  }, 10000);
                  return;
                }
              }

              //this.showDialogSearchGuid = false;
              //this.showDialogGuid = true;
              this.openDialog();
              this.guidSearch = "";
            } else {
              this.guidSearch = "";

              this.alert_errorR = true;
              this.errorMessageR = response.errorMessage;
              setTimeout(() => {
                this.alert_errorR = false;
                this.errorMessageR = "";
              }, 10000);
            }
          });
      }
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    currencyFormat6(value) {
      return helpers.formatMoney(value, 6);
    },
    tipoComp(value) {
      return helpers.getTipoComprobante(value);
    },

    closeError() {
      this.$store.commit("setAlertError", false);
    },
    closeOk() {
      this.$store.commit("setAlertOk", false);
    },

  },
  computed: {
    alert_ok() {
      return this.$store.state.alertOk;
    },
    alert_error() {
      return this.$store.state.alertError;
    },
    alertMessage() {
      return this.$store.state.alertMessage;
    },
    overlay() {
      return this.$store.state.overlay;
    },
    conFiel() {
      return this.$store.state.hasFiel;
    },
    conDescargaMasiva() {
      return this.$store.state.hasAutSatDownload;
    },
    esAdmin() {
      return this.$store.state.isAdministrator;
    },
    drawer: {
      get: function () {
        return this.$store.state.showDrawer;
      },
      set: function (value) {
        this.$store.commit("setShowDrawer", value);
      },
    },
    getUser() {
      let user = "";
      if (this.$store.state.entity === null) user = "Anónimo";
      else {
        user =
          this.$store.state.userLongName +
          " ( " +
          this.$store.state.entity +
          " )"; // + this.$store.state.usuario;
      }
      return user;
    },
    permisoFull() {
      let resultado = false;
      if (this.$store.state.accountType !== "FULL") resultado = true;
      else resultado = false;

      if (!this.$store.state.isInvoice)
        return true;
      return resultado;
    },
    ShowDrawer() {
      return !(this.$store.state.entity === null);
    },
    isPayroll() {
      return this.$store.state.isPayroll === true;
    },
    isInvoice() {
      return this.$store.state.isInvoice === true;
    },
    allowIssued() {
      return this.$store.state.allowIssued === true;
    },
    allowReceived() {
      return this.$store.state.allowReceived === true;
    },
    showSearchButtonGUID() {
      return this.guidSearch.length == 36;
    },
  },
};
</script>

<style scoped>
body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

.tooltip {
  position: relative;
  display: inline-block;

}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -60px;
  top: 150%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  margin-left: -5px;
  bottom: 100%;
  left: 50%;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext2 {
  visibility: hidden;
  width: 180px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -60px;
  top: 150%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext2::after {
  content: "";
  position: absolute;
  margin-left: -5px;
  bottom: 100%;
  left: 50%;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext2 {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext3 {
  visibility: hidden;
  width: 100px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -60px;
  top: 150%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext3::after {
  content: "";
  position: absolute;
  margin-left: -5px;
  bottom: 100%;
  left: 50%;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext3 {
  visibility: visible;
  opacity: 1;
}

.my-table {
  border-collapse: collapse;
}

.my-table thead tr,
.my-table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.my-table th,
.my-table td {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px;
}

.fixed-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  /* Ajusta el z-index según sea necesario para que quede por encima de otros elementos */
}

.move-left {
  margin-left: -92px;
}

.move-right{
  margin-left: 30px;
}

.close-btn {
  position: absolute;
  top: 50%;
  right: 16px;
  /* Ajusta según el margen deseado */
  transform: translateY(-50%);
}

.cursor-wait {
  cursor: wait !important;
}

.wj-control{
        font-family: 'Calibri' !important;
    }
</style>

